<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco Percorsi</h4>
                </div>
                <PickList
                    @move-to-source='onRemove'
                    @move-all-to-source='onRemove'
                    @moveAllToTarget='onAdd'
                    @reorder='onSort'
                    :show-source-controls='false'
                    @move-to-target='onAdd'
                    v-model="products"
                    listStyle="height:342px"
                    dataKey="id"
                >
                    <template #sourceheader> Disponibili </template>
                    <template #targetheader> Associati </template>
                    <template #item="slotProps">
                        <div class="flex flex-wrap p-2 align-items-center gap-3"  v-if="slotProps.item" >
                            <img class="  w-4rem shadow-2 flex-shrink-0 border-round" :src="slotProps.item.icon || 'https://picsum.photos/48/48'" :alt="slotProps.item.slug" />
                            <div class="flex-1 flex flex-column gap-2">
                                <span class="font-bold">{{slotProps.item.slug}}</span>
                            </div>
                        </div>
                    </template>
                </PickList>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Users/CompanyTeamPaths');
//Fetch all paths
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import dd_companies from '../../../mixins/dd_companies';

const commonStore = createNamespacedHelpers('Common');
const teamStore = createNamespacedHelpers('Users/CompanyTeams');

export default {
    components: {},
    data() {
        return {
            products: null,
            teamName: ""
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading', 'available']),
        ...teamStore.mapGetters(['row']),
        mapSelectedPathIds(){
            const m = {};
            if (!this.rows) return m;
            if (this.rows.length === 0) return m;
            this.rows.forEach(row => {
                m[row['path_id']] = row['rank'];
            })
            return m;
        },
        companyName(){
            if (this.company_id > 0) {
                const company = this.getCompany(this.company_id);
                if (!company) return "";
                return company.name;
            }
            return "";
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList', 'addPath', 'savePath', 'deletePath', 'fetchAvailable']),
        ...commonStore.mapMutations(['setExtraBreadcrumbs']),
        ...teamStore.mapActions(['fetchOne']),
        refreshList() {
            if (!this.team_id) return;

            this.loadTeam(this.company_id, this.team_id);

            this.fetchList({
                company_id: this.company_id,
                team_id: this.team_id,
            }).then(() => {
                if(this.available && this.available.length > 0) {
                    this.splitPaths();
                } else {
                    this.refreshPaths();
                }

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        splitPaths(){
            const existing = this.mapSelectedPathIds;
            const selected = {};
            const unselected = [];
            this.available.forEach(path => {
                if (existing[path['id']]){
                    selected[path['id']] = path;
                } else {
                    unselected.push(path);
                }
            });

            //do sort selected
            const sorted = [];
            this.rows.forEach(row => {
                sorted.push(selected[row['path_id']]);
            })
            this.products = [unselected, sorted];
        },
        refreshPaths(){
            if (!this.company_id) return;

            this.fetchAvailable({company_id: this.company_id}).then(
                () => {
                    this.splitPaths();
                }
            ).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        onAdd(ev){
            if (!ev.items) return;
            if (!ev.items.length) return;
            let numBefore = this.products[1].length;
            let newOrd = numBefore * 10;
            ev.items.forEach(item => {
                newOrd += 10
                this.addPath({
                    company_id: this.company_id,
                    team_id: this.team_id,
                    path_id: item.id,
                    ord: newOrd
                }).then().catch(err => {
                    this.ShowStandardError(err.response);
                });
            })
        },
        onRemove: function(ev) {
            if (!ev.items) return;
            if (!ev.items.length) return;
            ev.items.forEach(item => {
                this.deletePath({
                    company_id: this.company_id,
                    team_id: this.team_id,
                    path_id: item.id
                }).then().catch(err => {
                    this.ShowStandardError(err.response);
                });
            })
        },
        onSort(ev){
            if (!ev) return;
            if (!ev.value) return;
            if (!ev.value[1]) return;
            let newOrd = 0;
            ev.value[1].forEach(item => {
                newOrd += 10
                this.savePath({
                    company_id: this.company_id,
                    team_id: this.team_id,
                    path_id: item.id,
                    ord: newOrd
                }).then().catch(err => {
                    this.ShowStandardError(err.response);
                });
            })
        },
        setPageBreadcrumbs(){
            if (this.company_id > 0 && this.team_id > 0) {
                this.setExtraBreadcrumbs([
                    { label: this.companyName, to: '/app/users/companies/'+this.company_id},
                    { label: "Teams", to: '/app/users/companies/'+this.company_id+'/company_teams'},
                    { label: this.teamName }
                ]);
            }
        },
        loadTeam(company_id, id){
            if (id === 0) return;
            this.fetchOne({id: id, company_id: company_id}).then(res => {
                this.teamName = res["name"];
                this.setPageBreadcrumbs();
            }).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {});

        }
    },
    props: {
        company_id: {
            default: 0,
            type: Number
        },
        team_id: {
            default: 0,
            type: Number
        }

    },
    watch:{
        team_id(){
            this.refreshList();
        },
        dd_mapped_companies(){
            this.setPageBreadcrumbs();
        },
        company_id(){
            this.refreshPaths();
        }
    },
    mixins: [
        Notifications,
        Navigations,
        dd_companies
    ],
};
</script>
